var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "financial-receipt" } },
    [
      _c(
        "vs-tabs",
        {
          attrs: {
            id: "financial-receip-internal-tab",
            position: "left",
            color: "primary",
          },
        },
        [
          _c("vs-tab", { attrs: { label: "Carnê" } }, [
            _c("div", { staticClass: "tab-text" }, [_c("config-book-pay")], 1),
          ]),
          _c("vs-tab", { attrs: { label: "Juros e Multa" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("interest-list", { attrs: { "allow-search": true } })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Espécie" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("type-payment-list", { attrs: { "allow-search": true } })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Parcelamento" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("parcel-list", { attrs: { "allow-search": true } })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Máquina de Cartão" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("financial-card-list", { attrs: { "allow-search": true } })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Quitar Automaticamente" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("type-down-pay-list", { attrs: { "allow-search": true } })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Contas" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("account-list", { attrs: { "allow-search": true } })],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }