<template>
  <div>
    <sig-form
      :model="model"
      @saved="$emit('saved')"
      @show-sigform="showSigForm"
      @close-sigform="showSigForm(false)"
    >
      <template v-slot:form>
        <!-- descricao -->
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <input-value-percent
              v-model="interest"
              :label="model.$fields.value.label"
              @input="update"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <input-value-type
              class="ml-2"
              v-model="interestDay"
              :label="model.$fields.day.label"
              @input="update"
            />
          </vs-col>
        </vs-row>
         <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <input-value-percent
              v-model="fine"
              :label="model.$fields.fine.label"
              @input="update"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <input-value-type
              v-model="fineDay"
              class="ml-2"
              :label="model.$fields.fine_day.label"
              @input="update"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <type-payment-search-select v-model="typePayment" @selected="update"/>
          </vs-col>
        </vs-row>
      </template>
    </sig-form>
  </div>
</template>

<script>
import model from '@/store/financial/interest/model'
import SigForm from '@/components/SigForm'
import InputValuePercent from '@/components/InputValuePercent'
import InputValueType from '@/components/InputValueType'
import TypePaymentSearchSelect from '@/components/financial/typePayment/TypePaymentSearchSelect'

export default {
  components: {
    SigForm,
    InputValuePercent,
    InputValueType,
    TypePaymentSearchSelect
  },
  data () {
    return {
      model,
      typePayment: {
        options: [],
        data: []
      },
      interest:{
        type: '%',
        value: 0
      },
      interestDay: {
        value: 1,
        type: 'Dias',
        options: ['Dias']
      },
      fine:{
        type: '%',
        value: 0
      },
      fineDay: {
        value: 1,
        type: 'Dias',
        options: ['Dias']
      },
      eventSigForm: true
    }
  },
  methods: {
    register (single) {
      // só existe data se for em uma edição
      if (single) {
        this.model = single
        this.interest.value = this.model.value
        this.interest.type = this.model.type
        this.fine.value = this.model.fine
        this.fine.type = this.model.type_fine
        this.interestDay.value = this.model.day
        this.fineDay.value = this.model.fine_day
        if (this.model.type_payments.length) {
          this.typePayment.data = this.model.type_payments
          this.typePayment.options = this.model.type_payments.map(element => {
            return element.$options()
          })
        }
      }
    },
    showSigForm (value) {
      if (this.eventSigForm) {
        if (value) {
          this.register(value)
          this.$emit('show-sigform')
        } else {
          this.$emit('close-sigform')
        }
      }
    },
    searchAccountPlan () {
      if (this.accountPlan.length === 0) {
        this.$store.commit(
          this.model.$relations.accountPlan.model.$store.commit.nameSelect,
          model.$name
        )
        this.$refs.accountPlan.search(true)
      }
    },
    update () {
      this.model.value = this.interest.value
      this.model.type = this.interest.type
      this.model.fine = this.fine.value
      this.model.type_fine = this.fine.type
      this.model.type_fine = this.fine.type
      this.model.day = this.interestDay.value
      this.model.fine_day = this.fineDay.value
      this.model.type_payments = this.typePayment.data
    },
    openSigSelect (value) {
      if (value) {
        this.$store.commit(
          this.model.$relations.accountPlan.model.$store.commit.nameSelect,
          model.$name
        )
      }
    }
  }
}
</script>
