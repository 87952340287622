<template>
  <div>
    <vs-popup class="popupAlert" title="Demonstração do carnê" :active.sync="pdfViewerPopup" fullscreen>
        <object :data="sourcePdf" width="100%" style="height: 600px" type="application/pdf" />
    </vs-popup>
    <div class="vx-row">
    <div class="vx-col">
      <sig-input-number v-model="configPaymentBook.number_page" label="Limite por página" />
    </div>
    <div class="vx-col">
      <sig-input-number v-model="configPaymentBook.font_size" label="Tamanho da fonte" />
    </div>
    <div class="vx-col md:w-3/5">
      <vs-textarea  v-model="configPaymentBook.line" label="Observação" />
    </div>
  </div>
    <vs-divider />
    <div class="flex flex-row-reverse">
      <vs-button @click="save">Salvar</vs-button>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  components: {
  },
  data () {
    return {
      pdfViewerPopup: false,
      sourcePdf: null,
      configPaymentBook: {
        font_size: 10,
        number_page: 3,
        line: ''
      }
    }
  },
  methods: {
    save () {
      this.$vs.loading()
      axios.post(`${process.env.VUE_APP_API_URL}/financial/bookPay/config`, this.configPaymentBook, { responseType: 'blob' })
        .then((resp) => {
          this.sourcePdf = window.URL.createObjectURL(resp.data)
          this.pdfViewerPopup = true
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    }
  },
  created () {
    axios.get(`${process.env.VUE_APP_API_URL}/financial/bookPay/config`).then(e => {
      if (Array.isArray(e.data) && e.data.length) {
        this.configPaymentBook = e.data[0]
      }
    })
  }
}

</script>
