var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "financial-register-tabs" } },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass: "tabs-container px-6 pt-6",
            attrs: { slot: "no-body" },
            slot: "no-body",
          },
          [
            _c(
              "vs-tabs",
              {
                staticClass: "tab-action-btn-fill-conatiner",
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Caixa",
                      "icon-pack": "feather",
                      icon: "icon-info",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("cashier-config")],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Recebimentos",
                      "icon-pack": "feather",
                      icon: "icon-info",
                    },
                  },
                  [_c("div", { staticClass: "tab-text" }, [_c("receipt")], 1)]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }