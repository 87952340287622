<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">{{ label }}</label>
      <vx-input-group
        prependClasses="border border-solid border-grey border-r-0"
      >
        <template slot="prepend">
          <div class="prepend-text bg-primary btn-addon">
            <vs-button>{{ value.type }}</vs-button>
          </div>
        </template>
        <vs-input
          class="w-full"
          type="number"
          v-model="value.value"
          @input="changeInput"
        />
      </vx-input-group>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  props: {
    value: {},
    label:{}
  },
  methods: {
    changeInput () {
      if (this.$refs.value && this.value.type === 'R$' && this.value.currencyValue !== 'R$ 0,00') {
        this.value.value = getValue(this.$refs.value)
      }
      this.$emit('input', this.value)
    }
  },
  mounted () {
    this.value.currencyValue = this.value.currencyValue || this.value.value 
  }
}
</script>