var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    { staticClass: "w-full" },
    [
      _c(
        "vs-col",
        { staticClass: "w-full" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(
              _vm._s(_vm.model.$select.label) + " para aplicar juros e multa."
            ),
          ]),
          _c("v-select", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate.initial",
                value: "required",
                expression: "'required'",
                modifiers: { initial: true },
              },
            ],
            staticClass: "vs_select_over",
            attrs: {
              "data-vv-as": "Espécie",
              name: "especie",
              options: _vm.options,
              multiple: "",
              placeholder: "Selecione",
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.inputSelect },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function ({}) {
                  return [_vm._v(" Nenhum resultado encontrado. ")]
                },
              },
            ]),
            model: {
              value: _vm.value.options,
              callback: function ($$v) {
                _vm.$set(_vm.value, "options", $$v)
              },
              expression: "value.options",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("especie"),
                  expression: "errors.has('especie')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("especie")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }