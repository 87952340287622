<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">{{ model.$select.label }} para aplicar juros e multa.</label>
      <v-select
        v-model="value.options"
        data-vv-as="Espécie"
        @input="inputSelect"
        class="vs_select_over"
        v-validate.initial="'required'"
        name="especie"
        :options="options"
        multiple
        placeholder="Selecione"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span
        class="text-danger text-sm"
        v-show="errors.has('especie')"
        >{{ errors.first('especie') }}</span
      >
    </vs-col>
  </vs-row>
</template>

<script>
// Store Module
import model from '@/store/financial/typePayment/model'

export default {
  props: ['value', 'nameSelect'],
  data () {
    return {
      especie: null,
      model
    }
  },
  computed: {
    options () {
      return this.$store.getters[this.model.$store.getters.options]
    }
  },
  methods: {
    inputSelect () {
      this.value.data = this.value.options.map(element => {
        return {
          id: element.value
        }
      })
      this.$emit('selected')
    },
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('especie')
    },
    fetchData () {
      this.$store.dispatch(this.model.$store.dispatch.fetchGrid)
    }
  },
  mounted () {
    if (this.options.length === 0) {
      this.fetchData()
    }
  }
}
</script>

<style lang="scss">
.vs_select_over .vs__dropdown-menu {
  position: relative;
}
.vue_select_drop_size_98 .vs__dropdown-menu {
  max-height: 98px;
}
</style>
