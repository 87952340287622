var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popupAlert",
          attrs: {
            title: "Demonstração do carnê",
            active: _vm.pdfViewerPopup,
            fullscreen: "",
          },
          on: {
            "update:active": function ($event) {
              _vm.pdfViewerPopup = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: {
              data: _vm.sourcePdf,
              width: "100%",
              type: "application/pdf",
            },
          }),
        ]
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col" },
          [
            _c("sig-input-number", {
              attrs: { label: "Limite por página" },
              model: {
                value: _vm.configPaymentBook.number_page,
                callback: function ($$v) {
                  _vm.$set(_vm.configPaymentBook, "number_page", $$v)
                },
                expression: "configPaymentBook.number_page",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col" },
          [
            _c("sig-input-number", {
              attrs: { label: "Tamanho da fonte" },
              model: {
                value: _vm.configPaymentBook.font_size,
                callback: function ($$v) {
                  _vm.$set(_vm.configPaymentBook, "font_size", $$v)
                },
                expression: "configPaymentBook.font_size",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-3/5" },
          [
            _c("vs-textarea", {
              attrs: { label: "Observação" },
              model: {
                value: _vm.configPaymentBook.line,
                callback: function ($$v) {
                  _vm.$set(_vm.configPaymentBook, "line", $$v)
                },
                expression: "configPaymentBook.line",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse" },
        [_c("vs-button", { on: { click: _vm.save } }, [_vm._v("Salvar")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }