<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <vs-row class="w-full">
         <vs-col vs-w="4">
          <vs-divider>Geral</vs-divider>
          <sig-switch v-model="model" prop="split_income_expense" />
          <sig-switch prop="show_moviment_header" v-model="model" />
          <sig-input-enum v-model="balance" label="Período de Movimentação e Dia de início"/>
          <sig-vselect v-model="model" prop="list_fields" label="Lista de campos a exibir" multiple/>
        </vs-col>
        <vs-col vs-w="4" class="pl-2">
          <vs-divider>Lançamentos</vs-divider>
          <sig-switch prop="allow_cancel" v-model="model" />
          <sig-switch prop="allow_delete" v-model="model" />
          <sig-switch prop="allow_edit" v-model="model" />
          <sig-switch prop="individual" v-model="model" />
        </vs-col>
        <vs-col vs-w="4" class="pl-2">
          <vs-divider>Estorno</vs-divider>
          <sig-switch prop="show_chageback" v-model="model" />
          <sig-switch prop="sum_chageback_day" v-model="model" />
        </vs-col>
      </vs-row>
      <vs-row class="w-full">
         <vs-col class="flex flex-row-reverse">
          <vs-button color="primary" type="filled" @click="save" >Salvar</vs-button>
        </vs-col>
      </vs-row>
    </vs-col>
  </vs-row>
</template>

<script>
import SigSwitch from '@/components/sig/SigSwitch.vue'
import SigInputEnum from '@/components/sig/SigInputEnum.vue'
import SigVselect from '@/components/sig/SigVselect.vue'
import model from '@/store/cashier/configCashier/model'

export default {
  components: {
    SigSwitch,
    SigVselect,
    SigInputEnum
  },
  data () {
    return {
      model,
      balance: {
        value: 1,
        type: 'MONTHLY',
        label: 'Mês',
        enum: model.$fields.balance_type.options
      }
    }
  },
  computed: {
    single: {
      get () {
        return this.$store.state[this.model.$store.name].single
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.single, value)
      }
    }
  },
  methods: {
    save () {
      this.$vs.loading()
      this.model.balance_type = this.balance.type
      this.model.balance_start = this.balance.value
      this.single = this.model
      this.$store.dispatch(this.model.$store.dispatch.saveSingle)
        .then(() => {
          this.mixinNotify('Operação executada com sucesso')
        })
        .catch(this.mixinCatch)
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    async fetch () {
      await this.$store.dispatch(this.model.$store.dispatch.single)
      this.updateModel()
    },
    updateModel () {
      this.model = this.single.$clone()
      this.balance.type = this.model.balance_type
      this.balance.value = this.model.balance_start
      this.balance.label = this.model.balance_type_formated
    }
  },
  mounted () {
    if (this.single && this.single.id) this.updateModel()
    else this.fetch()
  }
}
</script>
