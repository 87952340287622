var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    { staticClass: "w-full" },
    [
      _c(
        "vs-col",
        { staticClass: "w-full" },
        [
          _c(
            "vs-row",
            { staticClass: "w-full" },
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "4" } },
                [
                  _c("vs-divider", [_vm._v("Geral")]),
                  _c("sig-switch", {
                    attrs: { prop: "split_income_expense" },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                  _c("sig-switch", {
                    attrs: { prop: "show_moviment_header" },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                  _c("sig-input-enum", {
                    attrs: { label: "Período de Movimentação e Dia de início" },
                    model: {
                      value: _vm.balance,
                      callback: function ($$v) {
                        _vm.balance = $$v
                      },
                      expression: "balance",
                    },
                  }),
                  _c("sig-vselect", {
                    attrs: {
                      prop: "list_fields",
                      label: "Lista de campos a exibir",
                      multiple: "",
                    },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { staticClass: "pl-2", attrs: { "vs-w": "4" } },
                [
                  _c("vs-divider", [_vm._v("Lançamentos")]),
                  _c("sig-switch", {
                    attrs: { prop: "allow_cancel" },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                  _c("sig-switch", {
                    attrs: { prop: "allow_delete" },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                  _c("sig-switch", {
                    attrs: { prop: "allow_edit" },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                  _c("sig-switch", {
                    attrs: { prop: "individual" },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { staticClass: "pl-2", attrs: { "vs-w": "4" } },
                [
                  _c("vs-divider", [_vm._v("Estorno")]),
                  _c("sig-switch", {
                    attrs: { prop: "show_chageback" },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                  _c("sig-switch", {
                    attrs: { prop: "sum_chageback_day" },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "w-full" },
            [
              _c(
                "vs-col",
                { staticClass: "flex flex-row-reverse" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }