var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    { staticClass: "w-full" },
    [
      _c(
        "vs-col",
        { staticClass: "w-full" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
          _c(
            "vx-input-group",
            {
              attrs: {
                prependClasses: "border border-solid border-grey border-r-0",
              },
            },
            [
              _c("template", { slot: "prepend" }, [
                _c(
                  "div",
                  { staticClass: "prepend-text bg-primary btn-addon" },
                  [_c("vs-button", [_vm._v(_vm._s(_vm.value.type))])],
                  1
                ),
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { type: "number" },
                on: { input: _vm.changeInput },
                model: {
                  value: _vm.value.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "value", $$v)
                  },
                  expression: "value.value",
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }