var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sig-form", {
        attrs: { model: _vm.model },
        on: {
          saved: function ($event) {
            return _vm.$emit("saved")
          },
          "show-sigform": _vm.showSigForm,
          "close-sigform": function ($event) {
            return _vm.showSigForm(false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "6",
                        },
                      },
                      [
                        _c("input-value-percent", {
                          attrs: { label: _vm.model.$fields.value.label },
                          on: { input: _vm.update },
                          model: {
                            value: _vm.interest,
                            callback: function ($$v) {
                              _vm.interest = $$v
                            },
                            expression: "interest",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "6",
                        },
                      },
                      [
                        _c("input-value-type", {
                          staticClass: "ml-2",
                          attrs: { label: _vm.model.$fields.day.label },
                          on: { input: _vm.update },
                          model: {
                            value: _vm.interestDay,
                            callback: function ($$v) {
                              _vm.interestDay = $$v
                            },
                            expression: "interestDay",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "6",
                        },
                      },
                      [
                        _c("input-value-percent", {
                          attrs: { label: _vm.model.$fields.fine.label },
                          on: { input: _vm.update },
                          model: {
                            value: _vm.fine,
                            callback: function ($$v) {
                              _vm.fine = $$v
                            },
                            expression: "fine",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "6",
                        },
                      },
                      [
                        _c("input-value-type", {
                          staticClass: "ml-2",
                          attrs: { label: _vm.model.$fields.fine_day.label },
                          on: { input: _vm.update },
                          model: {
                            value: _vm.fineDay,
                            callback: function ($$v) {
                              _vm.fineDay = $$v
                            },
                            expression: "fineDay",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "center",
                          "vs-w": "12",
                        },
                      },
                      [
                        _c("type-payment-search-select", {
                          on: { selected: _vm.update },
                          model: {
                            value: _vm.typePayment,
                            callback: function ($$v) {
                              _vm.typePayment = $$v
                            },
                            expression: "typePayment",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }